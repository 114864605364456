import {React, useEffect} from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'
import './style/style.css';
import {Route, Switch} from "react-router";
import Home from "./pages/Home";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import {Container} from "@material-ui/core";
import Services from "./pages/Services";
import CompositionContainer from "./pages/Composition";
import UsagesContainer from "./pages/Usages";
import AddCompositionContainer from "./pages/AddComposition";
import Page404 from "./pages/404";
import usePageTracking from "./history/analyticsTracker";
import {initialSync} from "./redux/user-reducer";
import {connect} from "react-redux";


const App = (props) => {
    usePageTracking();
    useEffect( () => {
        props.sync();
    }, []);

    const theme = createMuiTheme({
        typography: {
            fontFamily: 'Jura, Arial',
        },
        palette: {
            type: 'light',
            primary: {
                main: '#202938',
            },
            secondary: {
                main: '#6dc353',
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="wrapper">
                    <Header/>
                    <Container maxWidth="md">
                        <div className='app-wrapper-content'>
                            <Switch>
                                <Route exact path="/" render={() => <Home/>}/>
                                <Route exact path="/services" render={() => <Services/>}/>
                                <Route exact path="/services/composition/tv/add"
                                       render={() => <AddCompositionContainer/>}/>
                                <Route exact path="/services/composition/tv/:searchedItem"
                                       render={(props) => <CompositionContainer {...props}/>}/>
                                <Route exact path="/services/usages/tv/:searchedItem"
                                       render={(props) => <UsagesContainer {...props}/>}/>

                                {<Route path="*" component={Page404}/>}
                            </Switch>
                        </div>
                    </Container>
                    <Footer/>
                </div>
            </ThemeProvider>
        </>
    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        sync: () => {
            dispatch(initialSync());
        },
    }
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
export default AppContainer;
