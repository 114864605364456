import React from 'react';
import history from "../../history/history";
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import store from "../../redux/store";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import StyledTextField from "../entities/StyledTextField";
import StyledButton from "../entities/StyledButton";

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: '#202938',//theme.palette.background.paper,
        color: '#6dc353',
        fontWeight: '400',
    },
}));

const SearchForm = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    let search = (e) => {
        e.preventDefault();
        if (props.currentSearchText === '') {
            return;
        }

        if(props.currentSearchText.toString().length < 4)
        {
            setAnchorEl(e.currentTarget);
            return;
        }

        props.onSearch(props.currentSearchText);

        if( props.redirectToResults )
        {
            let st = store.getState();
            let item = st.compositionPage.searchedItem;
            if (props.stype === 'usages') {
                item = st.usagesPage.searchedItem;
            }
            const url = `/services/${props.stype}/tv/${item}`;
            history.push(url);
        }
    }

    let onSearchTextChange = (evt) => {
        setAnchorEl(null);
        let text = evt.target.value;
        props.updateSearchText(text);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    return (
        <>
            <form onSubmit={search}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={8} sm={10}>
                        <StyledTextField
                            label={props.searchType}
                            type="search"
                            onChange={onSearchTextChange}
                            value={props.currentSearchText}
                            placeholder={props.placeholder}
                            icon={<SearchIcon/>}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <StyledButton
                            label={"Искать"}
                            icon={<SearchIcon/>}
                            type="submit"
                        />
                        <Popper id={id} open={open} anchorEl={anchorEl} placement="top" transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <div className={classes.paper}>Введите хотя бы 4 символа</div>
                                </Fade>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default SearchForm;