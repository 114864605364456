import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import store from './redux/store'
import {Provider} from 'react-redux';
import history from "./history/history";
import ScrollToTop from "./components/ScrollToTop";
import {initYM} from "./history/analyticsTracker";
import AppContainer from "./App";

//const unsubscribe = store.subscribe(() => {
//    console.log(store.getState())
//});

ReactDOM.render(
    <React.StrictMode>
        {initYM()}

        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop/>
                <AppContainer/>
            </Router>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
