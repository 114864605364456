import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {
    updateTcnPn1AC, updateTcnPn2AC, updateTcnPn3AC,
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const TcnAddForm = (props) => {

    return(
        <>
            <FormGroup3pn
                title={"T-Con/Тайминг-контроллер/Тикон"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01939B\", а на наклейке \"LSF400HF02\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01939B\", а в поле \"Партнамбер 2\" - \"LSF400HF02\"."}
                val_pn1={props.tcn1}
                val_pn2={props.tcn2}
                val_pn3={props.tcn3}
                cb_pn1={props.updateTcnPn1}
                cb_pn2={props.updateTcnPn2}
                cb_pn3={props.updateTcnPn3}
                pn1_err={props.tcn1Err}
                pn2_err={props.tcn2Err}
                pn3_err={props.tcn3Err}
                pn1_errDesc={props.tcn1ErrWhat}
                pn2_errDesc={props.tcn2ErrWhat}
                pn3_errDesc={props.tcn3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        tcn1: state.addCompositionPage.tcn_pn1,
        tcn2: state.addCompositionPage.tcn_pn2,
        tcn3: state.addCompositionPage.tcn_pn3,
        tcn1Err: state.addCompositionPage.tcn_pn1Err,
        tcn2Err: state.addCompositionPage.tcn_pn2Err,
        tcn3Err: state.addCompositionPage.tcn_pn3Err,
        tcn1ErrWhat: state.addCompositionPage.tcn_pn1ErrWhat,
        tcn2ErrWhat: state.addCompositionPage.tcn_pn2ErrWhat,
        tcn3ErrWhat: state.addCompositionPage.tcn_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateTcnPn1: (val, err) => { dispatch(updateTcnPn1AC(val, err)); },
        updateTcnPn2: (val, err) => { dispatch(updateTcnPn2AC(val, err)); },
        updateTcnPn3: (val, err) => { dispatch(updateTcnPn3AC(val, err)); },
    }
}
const TcnAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(TcnAddForm);
export default TcnAddFormContainer;