import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cssOutlinedInput: {
        margin: 5,
        marginBottom: 10,
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
            fontWeight: '600',
        }
    },
    cssFocused: {
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#202938 !important',
    },
    input: {
        color: '#202938',
        fontWeight: 600,
        maxWidth: '600px',
    },
    error: {
        margin: 5,
        marginBottom: 10,
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.error} !important`,
            fontWeight: '600',
        },
        borderWidth: '1px',
        borderColor: '#FF2938 !important',
        color: '#FF2938',
        fontWeight: 600,
        maxWidth: '600px',
    },
}));

const StyledTextField = (props) => {
    const classes = useStyles();

    return (
        <>
            <TextField
                label={props.label}
                type={props.type}
                variant="outlined"
                fullWidth={true}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder}
                size="small"
                InputProps={{
                    classes: {
                        root: props.error ? classes.error : classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: props.error ? classes.error : classes.input,
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            {props.icon}
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: {
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '100%',
                        color: '#202938',
                        fontWeight: '600',
                    }
                }}
                error={props.err}
                helperText={props.errWhat}
                disabled={props.disabled}
            />
        </>
    )
}

export default StyledTextField;