import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import ym, {YMInitializer} from 'react-yandex-metrika';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize("UA-192913762-1");
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
            if (!window.location.href.includes("ignore_prerender")) {
                ym('hit', location.pathname + location.search);
            }
        }
    }, [initialized, location]);
};

const initYM = () => {
    if (!window.location.href.includes("ignore_prerender")) {
        return (
            <YMInitializer accounts={[76635342]} options={{
                defer: true,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            }} version="2"/>
        )
    }
}

export default usePageTracking;
export {initYM};