import React from 'react'
import SearchModelFormContainer from "../components/search/SearchModelForm";
import SearchPnFormContainer from "../components/search/SearchPnForm";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {makeStyles} from "@material-ui/core/styles";
import logo from "../components/logo.svg";
import MetaTags from "react-meta-tags";
import StyledButton from "../components/entities/StyledButton";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import {Grid} from "@material-ui/core";
import {Link as ReactLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const Services = () => {
    const classes = useStyles();

    return (
        <>
            <MetaTags>
                <title>{`Сервисы - наТекстолите`}</title>
                <meta name="description" content={`Сервисы - наТекстолите`} />
                <meta property="og:title" content="naTeXtolite" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`${window.location.href}`} />
                <meta property="og:image" content={logo} />
                <meta property="og:description" content={`Сервисы - наТекстолите`} />
            </MetaTags>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon}/>
                    naTeXtolite
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <ClearAllIcon className={classes.icon}/>
                    Сервисы
                </Typography>
            </Breadcrumbs>

            <h1>Сервисы</h1>
            <p>На данный момент НаТекстолите вы можете найти технические составы телевизоров наиболее
                распространенных производителей, таких как Samsung, LG, Sony и др. Мы ведем
                работу по наполнению базы, а теперь и любой желающий может присоединиться и внести
                состав ТВ, которого еще нет в базе (для этого нажмите кнопку "Добавить состав" внизу страницы)
            </p>
            <p>
                Чтобы получить состав ТВ, введите его модель в поле ниже и нажмите "Искать"
            </p>
            <SearchModelFormContainer/>
            <p>
                Часто, бывает полезно знать в каких моделях ТВ установлен тот или иной модуль. Однако информацию
                приходится собирать по крупицам, затрачивая уйму времени.
                Специально для таких случаев мы добавили "реверсивный поиск" - сервис, который ищет в каких ТВ
                установлена искомая деталь.
            </p>
            <p>
                Введите парт-намбер искомой детали в поле ниже и нажмите кнопку "Искать"
            </p>
            <SearchPnFormContainer/>

            <p>
                Доступно внесение составов в базу!
                Для зарегистрированных пользователей стало доступно добавление составов неизвестных ТВ.
                Теперь каждый может принять участие в улучшении и развитии сервиса.
            </p>

            <Grid container direction="row" justify="center" alignItems="center" >
                <ReactLink to={"/services/composition/tv/add"}  style={{ textDecoration: 'none' }}>
                    <StyledButton
                        label={"Добавить состав"}
                        icon={<QueuePlayNextIcon/>}
                    />
                </ReactLink>
            </Grid>

            {window.prerenderReady = true}
        </>
    )
}

export default Services;