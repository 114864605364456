import React from 'react'
import PanToolIcon from '@mui/icons-material/PanTool';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {styled} from "@mui/material/styles";
import {Container, Grid} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 5,
        background: '#202938',
    },
    card: {
        padding: '0px',
        paddingBottom: '0px !important',
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'white',
    },
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
});

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center'
}));


const CompositionCheckerResults = (props) => {
    const classes = useStyles();

    const renderCheckerResults = () => {
        if (props.isFound) {
            return (
                <>
                    <StyledContainer maxWidth="md">
                    <PanToolIcon
                        className={classes.iconButton}
                        fontSize={'small'}/>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Модель найдена в базе
                    </Typography>
                    </StyledContainer>
                </>
            )
        }
        else
        {
            return (
                <>
                    <StyledContainer maxWidth="md">
                    <CheckCircleIcon
                        className={classes.iconButton}
                        fontSize={'small'}/>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Модель неизвестна
                    </Typography>
                    </StyledContainer>
                </>
            )
        }
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={10} sm={10}>
                <Card className={classes.root} variant="outlined">
                    <CardContent className={classes.card}>
                        {renderCheckerResults()}
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default CompositionCheckerResults;