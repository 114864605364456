import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Typography from "@material-ui/core/Typography";
import CallSplitIcon from '@material-ui/icons/CallSplit';
import UsagesResultContainer from "../components/Usages";
import {connect} from "react-redux";
import {
    fetchUsages,
    updateSearchPnTextActionCreator,
} from "../redux/usages-reducer";
import SearchPnFormContainer from "../components/search/SearchPnForm";
import MetaTags from 'react-meta-tags';
import logo from '../components/logo.svg'

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));

const Usages = ({syncState, searchedItem, match}) => {
    const classes = useStyles();

    useEffect(() => {
        if (searchedItem === "") {
            syncState(match.params.searchedItem);
        }
    })

    return (
        <>
            <MetaTags>
                <title>{`Модели ТВ с ${searchedItem.split("____").join("/")} на борту - наТекстолите`}</title>
                <meta name="description" content={`Модели ТВ с ${searchedItem.split("____").join("/")} на борту`}/>
                <meta name="keywords" content={`Состав,аналоги,использование,применяется в,${searchedItem.split("____").join("/")}`} />
                <meta property="og:title" content="naTeXtolite" />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${window.location.href}`}/>
                <meta property="og:image" content={logo}/>
                <meta property="og:description" content={`Модели ТВ с ${searchedItem.split("____").join("/")} на борту`}/>
            </MetaTags>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="textPrimary" href="/" className={classes.link}>
                    <HomeIcon className={classes.icon}/>
                    naTeXtolite
                </Link>
                <Link color="textPrimary" href="/services" className={classes.link}>
                    <ClearAllIcon className={classes.icon}/>
                    Сервисы
                </Link>
                <Typography color="textPrimary" className={classes.link}>
                    <CallSplitIcon className={classes.icon}/>
                    Поиск по вхождению в состав
                </Typography>
            </Breadcrumbs>

            <SearchPnFormContainer/>
            <h1>Результаты поиска:</h1>
            {/*<div> Тип поиска: {searchTypeName(props.searchType)} </div>
            <div> Объект поиска: {props.searchedItem} </div>*/}

            <UsagesResultContainer/>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        currentPnSearchText: state.usagesPage.searchPnText,
        searchedItem: state.usagesPage.searchedItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncState: (newText) => {
            dispatch(updateSearchPnTextActionCreator(newText));
            dispatch(fetchUsages(newText));
        },
    }
}

const UsagesContainer = connect(mapStateToProps, mapDispatchToProps)(Usages);
export default UsagesContainer;