import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {
    updateBtnPn1AC, updateBtnPn2AC, updateBtnPn3AC,
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const BtnAddForm = (props) => {

    return(
        <>
            <FormGroup3pn
                title={"Кнопки/Джойстик/Сенсорная плата"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.btn1}
                val_pn2={props.btn2}
                val_pn3={props.btn3}
                cb_pn1={props.updateBtnPn1}
                cb_pn2={props.updateBtnPn2}
                cb_pn3={props.updateBtnPn3}
                pn1_err={props.btn1Err}
                pn2_err={props.btn2Err}
                pn3_err={props.btn3Err}
                pn1_errDesc={props.btn1ErrWhat}
                pn2_errDesc={props.btn2ErrWhat}
                pn3_errDesc={props.btn3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        btn1: state.addCompositionPage.btn_pn1,
        btn2: state.addCompositionPage.btn_pn2,
        btn3: state.addCompositionPage.btn_pn3,
        btn1Err: state.addCompositionPage.btn_pn1Err,
        btn2Err: state.addCompositionPage.btn_pn2Err,
        btn3Err: state.addCompositionPage.btn_pn3Err,
        btn1ErrWhat: state.addCompositionPage.btn_pn1ErrWhat,
        btn2ErrWhat: state.addCompositionPage.btn_pn2ErrWhat,
        btn3ErrWhat: state.addCompositionPage.btn_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBtnPn1: (val, err) => { dispatch(updateBtnPn1AC(val, err)); },
        updateBtnPn2: (val, err) => { dispatch(updateBtnPn2AC(val, err)); },
        updateBtnPn3: (val, err) => { dispatch(updateBtnPn3AC(val, err)); },
    }
}
const BtnAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(BtnAddForm);
export default BtnAddFormContainer;