import {combineReducers, createStore, applyMiddleware} from 'redux';
import compositionReducer from "./composition-reducer";
import usagesReducer from "./usages-reducer";
import userReducer from "./user-reducer";
import addCompositionReducer from "./add-composition-reducer";
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';

let reducers = combineReducers({
    compositionPage: compositionReducer,
    usagesPage: usagesReducer,
    userState: userReducer,
    addCompositionPage: addCompositionReducer,
});

//const loggerMiddleware = createLogger();
let store = createStore(
    reducers,
    applyMiddleware(
        thunkMiddleware,
//        loggerMiddleware
    )
);

export default store;

window.store = store;