import {FormGroup6pn} from "../../FormGroup";
import React from "react";
import {
    updateBklPn1AC, updateBklPn2AC, updateBklPn3AC, updateBklPn4AC, updateBklPn5AC, updateBklPn6AC,
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const BklAddForm = (props) => {

    return(
        <>
            <FormGroup6pn
                title={"LED подсветка"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.bkl1}
                val_pn2={props.bkl2}
                val_pn3={props.bkl3}
                val_pn4={props.bkl4}
                val_pn5={props.bkl5}
                val_pn6={props.bkl6}
                cb_pn1={props.updateBklPn1}
                cb_pn2={props.updateBklPn2}
                cb_pn3={props.updateBklPn3}
                cb_pn4={props.updateBklPn4}
                cb_pn5={props.updateBklPn5}
                cb_pn6={props.updateBklPn6}
                pn1_err={props.bkl1Err}
                pn2_err={props.bkl2Err}
                pn3_err={props.bkl3Err}
                pn4_err={props.bkl4Err}
                pn5_err={props.bkl5Err}
                pn6_err={props.bkl6Err}
                pn1_errDesc={props.bkl1ErrWhat}
                pn2_errDesc={props.bkl2ErrWhat}
                pn3_errDesc={props.bkl3ErrWhat}
                pn4_errDesc={props.bkl4ErrWhat}
                pn5_errDesc={props.bkl5ErrWhat}
                pn6_errDesc={props.bkl6ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        bkl1: state.addCompositionPage.bkl_pn1,
        bkl2: state.addCompositionPage.bkl_pn2,
        bkl3: state.addCompositionPage.bkl_pn3,
        bkl4: state.addCompositionPage.bkl_pn4,
        bkl5: state.addCompositionPage.bkl_pn5,
        bkl6: state.addCompositionPage.bkl_pn6,
        bkl1Err: state.addCompositionPage.bkl_pn1Err,
        bkl2Err: state.addCompositionPage.bkl_pn2Err,
        bkl3Err: state.addCompositionPage.bkl_pn3Err,
        bkl4Err: state.addCompositionPage.bkl_pn4Err,
        bkl5Err: state.addCompositionPage.bkl_pn5Err,
        bkl6Err: state.addCompositionPage.bkl_pn6Err,
        bkl1ErrWhat: state.addCompositionPage.bkl_pn1ErrWhat,
        bkl2ErrWhat: state.addCompositionPage.bkl_pn2ErrWhat,
        bkl3ErrWhat: state.addCompositionPage.bkl_pn3ErrWhat,
        bkl4ErrWhat: state.addCompositionPage.bkl_pn4ErrWhat,
        bkl5ErrWhat: state.addCompositionPage.bkl_pn5ErrWhat,
        bkl6ErrWhat: state.addCompositionPage.bkl_pn6ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBklPn1: (val, err) => { dispatch(updateBklPn1AC(val, err)); },
        updateBklPn2: (val, err) => { dispatch(updateBklPn2AC(val, err)); },
        updateBklPn3: (val, err) => { dispatch(updateBklPn3AC(val, err)); },
        updateBklPn4: (val, err) => { dispatch(updateBklPn4AC(val, err)); },
        updateBklPn5: (val, err) => { dispatch(updateBklPn5AC(val, err)); },
        updateBklPn6: (val, err) => { dispatch(updateBklPn6AC(val, err)); },
    }
}
const BklAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(BklAddForm);
export default BklAddFormContainer;