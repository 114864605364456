import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: '#000000',
        borderRadius: 4,
        '&:hover': {
            borderColor: '#000000',
        },
        '&:focus': {
            borderColor: '#000000',
        },
        fontWeight: 600,
    },
    button: {
        margin: theme.spacing(1),
    },
    label: {
        color: '#6dc353',
        textTransform: 'capitalize',
    },
}));

const StyledButton = (props) => {
    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                type={props.type}
                className={classes.button}
                startIcon={props.icon}
                size="medium"
                classes={{
                    root: classes.root,
                    label: classes.label,
                }}
                onClick={props.onClick}
                disabled={props.disabled}
            > {props.label} </Button>
        </>
    )
}

export default StyledButton;