import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LoginDialogViewContainer from "./LoginDialogView";
import RegisterDialogViewContainer from "./RegisterDialogView";
import {connect} from "react-redux";
import {hideLoginDialogAC, resetStateAC} from "../../redux/user-reducer";
import StyledButton from "../entities/StyledButton";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `action-tab-${index}`,
        'aria-controls': `action-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary,
        width: "auto",
        position: 'relative',
        minHeight: 200,
    },
    dialogHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
    },
}));

const LoginDialog = (props) => {
    const classes = useStyles();
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('sm');
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleClose = () => {
        props.hideLoginDialog();
    };

    const handleForgot = () => {
        props.hideLoginDialog();
    };

    return (
        <>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={props.isVisible}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" className={classes.dialogHeader}>Войдите или зарегистрируйтесь</DialogTitle>

                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            aria-label="action tabs example"
                        >
                            <Tab label="Вход" {...a11yProps(0)} />
                            <Tab label="Регистрация" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <LoginDialogViewContainer onForgot={handleForgot}/>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <RegisterDialogViewContainer />
                        </TabPanel>
                    </SwipeableViews>
                </div>

                <DialogActions>
                    <StyledButton
                        type={"button"}
                        onClick={handleClose}
                        label={"Отмена"}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isVisible: state.userState.showLoginDialog,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        hideLoginDialog: () => {
            dispatch(hideLoginDialogAC());
            dispatch(resetStateAC());
        },
    }
}

const LoginDialogContainer = connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
export default LoginDialogContainer;