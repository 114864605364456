import fetch from 'cross-fetch';

const REQUEST_COMPOSITION = 'REQUEST_COMPOSITION';
const RECEIVE_COMPOSITION = 'RECEIVE_COMPOSITION';
const CLEAR_SECONDARY_USAGES = 'CLEAR_SECONDARY_USAGES';
const RECEIVE_SECONDARY_USAGES = 'RECEIVE_SECONDARY_USAGES';

const UPDATE_SEARCH_MODEL_TEXT = 'UPDATE_SEARCH_MODEL_TEXT';

const SEARCH_TYPE_MODEL = 'SEARCH_TYPE_MODEL';

let initialState = {
    searchModelText: '',
    searchType: '',
    searchedItem: '',
    isFetching: true,
    tv_composition: {
        empty: true,
        vendor: '',
        model: '',
        chasis: '',
        pseudonim: '',

        ssb: [ // alternatives
            {id: '', pns: []}, // part-numbers of the current alt
        ],
        psu: [
            {id: '', pns: []},
        ],
        tcn: [
            {id: '', pns: []},
        ],
        bkl: [
            {id: '', pns: []},
        ],
        pnl: [
            {id: '', pns: []},
        ],
        ld: [
            {id: '', pns: []},
        ],
        ir: [
            {id: '', pns: []},
        ],
        btn: [
            {id: '', pns: []},
        ],
        wlan: [
            {id: '', pns: []},
        ],
        bt: [
            {id: '', pns: []},
        ],
        dyn: [
            {id: '', pns: []},
        ],
        tun: [
            {id: '', pns: []},
        ],
        lvds: [
            {id: '', pns: []},
        ],
        mtx: [
            {id: '', pns: []},
        ],
        cam: [
            {id: '', pns: []},
        ],
        inv: [
            {id: '', pns: []},
        ],
    },
    isSecondaryUsagesFetching: false,
    secondaryUsages: {
        empty: true,
        usages: [
            {id: '', model: ''},
        ],
    }
};

const compositionReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_MODEL_TEXT:
            return Object.assign({}, state, {
                searchModelText: action.newText
            });
        case REQUEST_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: true,
                searchedItem: state.searchModelText.split("/").join("____"),
                searchType: SEARCH_TYPE_MODEL,
                searchModelText: '',
                isSecondaryUsagesFetching: false,
                secondaryUsages: {
                    empty: true,
                    usages: [],
                },
            });
        case RECEIVE_COMPOSITION:
            return Object.assign({}, state, {
                isFetching: false,
                tv_composition: action.composition,
            });

        case CLEAR_SECONDARY_USAGES:
            return Object.assign({}, state, {
                isSecondaryUsagesFetching: true,
                secondaryUsages: {
                    usages: [],
                },
            });

        case RECEIVE_SECONDARY_USAGES:
            return Object.assign({}, state, {
                    isSecondaryUsagesFetching: false,
                    secondaryUsages: {
                        empty: action.secondaryUsages.empty,
                        usages: action.secondaryUsages.usages,
                    }
                }
            );

        default:
            return state;
    }
};

export const requestCompositionAC = () => ({type: REQUEST_COMPOSITION})
export const clearSecondaryUsagesAC = () => ({type: CLEAR_SECONDARY_USAGES})

export function fetchComposition(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        dispatch(requestCompositionAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/composition/${what}`)
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveCompositionAC(json))
            )
    }
}

export function fetchSecondaryUsages(arr) {
    return function (dispatch) {
        dispatch(clearSecondaryUsagesAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/usages/${arr[0].split("/").join("____")}`)
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveSecondaryUsagesAC(json))
            )
    }
}

export const receiveCompositionAC = (json) => ({
    type: RECEIVE_COMPOSITION,
    composition: json.data.tv_composition
})

export const receiveSecondaryUsagesAC = (json) => ({
    type: RECEIVE_SECONDARY_USAGES,
    secondaryUsages: json.data,
})

export const updateSearchModelTextActionCreator = (text) =>
    ({type: UPDATE_SEARCH_MODEL_TEXT, newText: text})

export default compositionReducer;