import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import StyledTextField from "./entities/StyledTextField";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 5,
        background: '#202938',
    },
    card: {
        padding: '0px',
        paddingBottom: '0px !important',
    },
    title: {
        fontSize: 17,
        fontWeight: 600,
        color: '#202938',
        paddingLeft: '5px'
    },
    description: {
        fontWeight: 500,
        color: '#202938',
        paddingBottom: '5px',
    },
    helper: {
        paddingLeft: '10px',
        fontSize: 'large',
        color: '#6dc353',
    }
});

/*
* Interface (props):
* props.title -> Title, showed on top of the content
* props.description -> Description (text) to be displayed before the form but next ot title
* props.val_pn1 -> field in state corresponding to pn1
* props.val_pn2 -> field in state corresponding to pn2
* props.val_pn3 -> field in state corresponding to pn3
* props.cb_pn1 -> onChange callback to be involved when pn1 changes
* props.cb_pn2 -> onChange callback to be involved when pn2 changes
* props.cb_pn3 -> onChange callback to be involved when pn3 changes
 */

const FormGroup3pn = (props) => {
    const classes = useStyles();

    let checkCyrillic = ( text ) => {
        let ru = /[а-яё]+/i.test(text);
        return ru;
    }

    let checkUpdatePn1 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn1( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn2 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn2( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn3 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn3( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.title}
                </Typography>
                <Typography className={classes.description} color="textSecondary" gutterBottom>
                    {props.description}
                </Typography>
                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 1"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn1}
                        value={props.val_pn1}
                        err={props.pn1_err}
                        errWhat={props.pn1_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 2"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn2}
                        value={props.val_pn2}
                        err={props.pn2_err}
                        errWhat={props.pn2_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 3"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn3}
                        value={props.val_pn3}
                        err={props.pn3_err}
                        errWhat={props.pn3_errDesc}
                    />
                </Grid>
            </Grid>
        </>
    )
}

/*
* Interface (props):
* props.title -> Title, showed on top of the content
* props.description -> Description (text) to be displayed before the form but next ot title
* props.val_pn1 -> field in state corresponding to pn1
* props.val_pn2 -> field in state corresponding to pn2
* props.cb_pn1 -> onChange callback to be involved when pn1 changes
* props.cb_pn2 -> onChange callback to be involved when pn2 changes
 */

const FormGroup2pn = (props) => {
    const classes = useStyles();

    let checkCyrillic = ( text ) => {
        let ru = /[а-яё]+/i.test(text);
        return ru;
    }

    let checkUpdatePn1 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn1( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn2 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn2( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.title}
                </Typography>
                <Typography className={classes.description} color="textSecondary" gutterBottom>
                    {props.description}
                </Typography>
                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 1"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn1}
                        value={props.val_pn1}
                        err={props.pn1_err}
                        errWhat={props.pn1_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 2"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn2}
                        value={props.val_pn2}
                        err={props.pn2_err}
                        errWhat={props.pn2_errDesc}
                    />
                </Grid>
            </Grid>
        </>
    )
}

/*
* Interface (props):
* props.title -> Title, showed on top of the content
* props.description -> Description (text) to be displayed before the form but next ot title
* props.val_pn1 -> field in state corresponding to pn1
* props.val_pn2 -> field in state corresponding to pn2
* props.val_pn3 -> field in state corresponding to pn3
* props.val_pn4 -> field in state corresponding to pn4
* props.val_pn5 -> field in state corresponding to pn5
* props.val_pn6 -> field in state corresponding to pn6
* props.cb_pn1 -> onChange callback to be involved when pn1 changes
* props.cb_pn2 -> onChange callback to be involved when pn2 changes
* props.cb_pn3 -> onChange callback to be involved when pn3 changes
* props.cb_pn4 -> onChange callback to be involved when pn4 changes
* props.cb_pn5 -> onChange callback to be involved when pn5 changes
* props.cb_pn6 -> onChange callback to be involved when pn6 changes
 */

const FormGroup6pn = (props) => {
    const classes = useStyles();

    let checkCyrillic = ( text ) => {
        let ru = /[а-яё]+/i.test(text);
        return ru;
    }

    let checkUpdatePn1 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn1( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn2 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn2( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn3 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn3( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn4 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn4( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn5 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn5( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }
    let checkUpdatePn6 = (e)=> {
        let invalid = checkCyrillic(e.target.value);
        props.cb_pn6( e.target.value, {
            isErr: invalid,
            what: invalid ? "Используйте буквы английского алфавита" : "" });
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">

                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {props.title}
                </Typography>
                <Typography className={classes.description} color="textSecondary" gutterBottom>
                    {props.description}
                </Typography>
                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 1"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn1}
                        value={props.val_pn1}
                        err={props.pn1_err}
                        errWhat={props.pn1_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 2"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn2}
                        value={props.val_pn2}
                        err={props.pn2_err}
                        errWhat={props.pn2_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 3"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn3}
                        value={props.val_pn3}
                        err={props.pn3_err}
                        errWhat={props.pn3_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 4"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn4}
                        value={props.val_pn4}
                        err={props.pn4_err}
                        errWhat={props.pn4_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 5"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn5}
                        value={props.val_pn5}
                        err={props.pn5_err}
                        errWhat={props.pn5_errDesc}
                    />
                </Grid>

                <Grid item xs={12} sm={10}>
                    <StyledTextField
                        label={"Партнамбер 6"}
                        placeholder={"Оставьте пустым если неизвестно"}
                        onChange={checkUpdatePn6}
                        value={props.val_pn6}
                        err={props.pn6_err}
                        errWhat={props.pn6_errDesc}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export { FormGroup3pn, FormGroup2pn, FormGroup6pn };

