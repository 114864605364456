import {connect} from "react-redux";
import React from "react";
import StyledTextField from "./entities/StyledTextField";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import StyledButton from "./entities/StyledButton";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import {updateModelAC, updateVendorAC, updateChasisAC, sendComposition} from "../redux/add-composition-reducer";
import SsbAddFormContainer from "./entities/composition-add/SsbAddForm";
import PsuAddFormContainer from "./entities/composition-add/PsuAddForm";
import TcnAddFormContainer from "./entities/composition-add/TcnAddForm";
import IrAddFormContainer from "./entities/composition-add/IrAddForm";
import BtnAddFormContainer from "./entities/composition-add/BtnAddForm";
import WlAddFormContainer from "./entities/composition-add/WlAddForm";
import BtAddFormContainer from "./entities/composition-add/BtAddForm";
import DynAddFormContainer from "./entities/composition-add/DynAddForm";
import BklAddFormContainer from "./entities/composition-add/BklAddForm";
import TnAddFormContainer from "./entities/composition-add/TnAddForm";
import InvAddFormContainer from "./entities/composition-add/InvAddForm";
import PnlAddFormContainer from "./entities/composition-add/PnlAddForm";
import LvdsAddFormContainer from "./entities/composition-add/LvdsAddForm";
import MtxAddFormContainer from "./entities/composition-add/MtxAddForm";
import store from "../redux/store";
import LdAddFormContainer from "./entities/composition-add/LdAddForm";
import CamAddFormContainer from "./entities/composition-add/CamAddForm";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 5,
        background: '#202938',
    },
    card: {
        padding: '0px',
        paddingBottom: '0px !important',
    },
    title: {
        fontSize: 17,
        fontWeight: 600,
        color: '#202938',
        paddingLeft: '5px'
    },
    description: {
        fontWeight: 500,
        color: '#202938',
        paddingBottom: '5px',
    },
    helper: {
        paddingLeft: '10px',
        fontSize: 'large',
        color: '#6dc353',
    }
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompositionForm = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    let errors = (state) => {
        return (
            state.vendorErr   ||
            state.modelErr    ||
            state.chasisErr   ||
            state.ssb_pn1Err  ||
            state.ssb_pn2Err  ||
            state.ssb_pn3Err  ||
            state.psu_pn1Err  ||
            state.psu_pn2Err  ||
            state.psu_pn3Err  ||
            state.tcn_pn1Err  ||
            state.tcn_pn2Err  ||
            state.tcn_pn3Err  ||
            state.ir_pn1Err   ||
            state.ir_pn2Err   ||
            state.ir_pn3Err   ||
            state.btn_pn1Err  ||
            state.btn_pn2Err  ||
            state.btn_pn3Err  ||
            state.wl_pn1Err   ||
            state.wl_pn2Err   ||
            state.wl_pn3Err   ||
            state.bt_pn1Err   ||
            state.bt_pn2Err   ||
            state.bt_pn3Err   ||
            state.dyn_pn1Err  ||
            state.dyn_pn2Err  ||
            state.dyn_pn3Err  ||
            state.cam_pn1Err  ||
            state.cam_pn2Err  ||
            state.cam_pn3Err  ||
            state.bkl_pn1Err  ||
            state.bkl_pn2Err  ||
            state.bkl_pn3Err  ||
            state.bkl_pn4Err  ||
            state.bkl_pn5Err  ||
            state.bkl_pn6Err  ||
            state.tn_pn1Err   ||
            state.tn_pn2Err   ||
            state.inv_pn1Err  ||
            state.inv_pn2Err  ||
            state.inv_pn3Err  ||
            state.pnl_pn1Err  ||
            state.pnl_pn2Err  ||
            state.lvds_pn1Err ||
            state.lvds_pn2Err ||
            state.mtx_pn1Err  ||
            state.mtx_pn2Err  ||
            state.ld_pn1Err   ||
            state.ld_pn2Err
        );
    }
    let empty = (state) => {
        return (
            state.ssb_pn1 === '' &&
            state.ssb_pn2 === '' &&
            state.ssb_pn3 === '' &&
            state.psu_pn1 === '' &&
            state.psu_pn2 === '' &&
            state.psu_pn3 === '' &&
            state.tcn_pn1 === '' &&
            state.tcn_pn2 === '' &&
            state.tcn_pn3 === '' &&
            state.ir_pn1 === ''  &&
            state.ir_pn2 === ''  &&
            state.ir_pn3 === ''  &&
            state.btn_pn1 === '' &&
            state.btn_pn2 ===''  &&
            state.btn_pn3 === '' &&
            state.wl_pn1 === ''  &&
            state.wl_pn2 === ''  &&
            state.wl_pn3 === ''  &&
            state.bt_pn1 === ''  &&
            state.bt_pn2 === ''  &&
            state.bt_pn3 === ''  &&
            state.dyn_pn1 === '' &&
            state.dyn_pn2 === '' &&
            state.dyn_pn3 === '' &&
            state.cam_pn1 === '' &&
            state.cam_pn2 === '' &&
            state.cam_pn3 === '' &&
            state.bkl_pn1 === '' &&
            state.bkl_pn2 === '' &&
            state.bkl_pn3 === '' &&
            state.bkl_pn4 === '' &&
            state.bkl_pn5 === '' &&
            state.bkl_pn6 === '' &&
            state.tn_pn1 === ''  &&
            state.tn_pn2 === ''  &&
            state.inv_pn1 === '' &&
            state.inv_pn2 === '' &&
            state.inv_pn3 === '' &&
            state.pnl_pn1 === '' &&
            state.pnl_pn2 === '' &&
            state.lvds_pn1 === ''&&
            state.lvds_pn2 === ''&&
            state.mtx_pn1 === '' &&
            state.mtx_pn2 === '' &&
            state.ld_pn1 === ''  &&
            state.ld_pn2 === ''
        );
    }

    const send = (e) => {
        e.preventDefault();

        const state = store.getState().addCompositionPage;
        if( state.vendor === '' )
        {
            setErrorMessage ("Вы не указали производителя ТВ");
            setOpen(true);
            return;
        }

        if(errors(state))
        {
            setErrorMessage ("Одно или несколько полей содержат кириллицу");
            setOpen(true);
            return;
        }

        if( empty(state) )
        {
            setErrorMessage ("Вы не указали ни одного партнамбера");
            setOpen(true);
            return;
        }

        props.onSend( state );
    }

    const render = () => {
        if( props.show )
        {
            return (
                <>
                    <form onSubmit={send}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Общая информация
                        </Typography>
                        <Typography className={classes.description} color="textSecondary" gutterBottom>
                            Поле 'Производитель ТВ' - обязательное. Это единственное поле, в котором допустима кириллица.
                        </Typography>
                            <Typography className={classes.description} color="textSecondary" gutterBottom>
                            Поле 'Модель ТВ' - заполняется автоматически после проверки наличия в базе такой модели и не является редактируемым.
                        </Typography>
                        <Typography className={classes.description} color="textSecondary" gutterBottom>
                            Внимательно проверьте модель ТВ, которую Вы указали. Если модель указана неверно - заново проверьте её через форму поиска выше.
                            Все заполненные поля при этом будут сброшены, поэтому будьте внимательны на этом этапе.
                        </Typography>
                        <Grid item xs={12} sm={10}>
                            <StyledTextField
                                label={"Производитель ТВ*"}
                                placeholder={"Напр. Samsung. Обязательное поле"}
                                onChange={props.updateVendor}
                                value={props.vendor}
                                err={props.vendorErr}
                                errWhat={props.vendorErr ? "Поле Производитель не может быть пустым": ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={10}>
                            <StyledTextField
                                label={"Модель ТВ"}
                                placeholder={"Модель полностью"}
                                onChange={props.updateModel}
                                value={props.model}
                                disabled={true}
                                err={props.modelErr}
                                errWhat={props.modelErr ? "Модель не может содержать кириллицу": ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={10}>
                            <StyledTextField
                                label={"Шасси (Chasis)"}
                                placeholder={"Оставьте пустым если неизвестно"}
                                onChange={props.updateChasis}
                                value={props.chasis}
                            />
                        </Grid>
                    </Grid>

                    <SsbAddFormContainer />
                    <PsuAddFormContainer />
                    <TcnAddFormContainer />
                    <IrAddFormContainer  />
                    <BtnAddFormContainer />
                    <WlAddFormContainer  />
                    <BtAddFormContainer  />
                    <DynAddFormContainer />
                    <BklAddFormContainer />
                    <TnAddFormContainer  />
                    <LdAddFormContainer  />
                    <InvAddFormContainer />
                    <PnlAddFormContainer />
                    <LvdsAddFormContainer/>
                    <MtxAddFormContainer />
                    <CamAddFormContainer />

                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <StyledButton
                                label={"Отправить состав"}
                                icon={<DoneOutlineIcon/>}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                    </form>

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert severity="error">{errorMessage}</Alert>
                    </Snackbar>
                </>
            )
        }
        else
        {
            return (
                <>
                </>
            )
        }
    }

    return (
        <>
            {render()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        searchedItem: state.addCompositionPage.searchedItem.replace("____", "/"),
        isFound: !state.addCompositionPage.tv_composition.empty,
        isChecking: state.addCompositionPage.isFetching,
        notStarted: !state.addCompositionPage.tried,

        vendor: state.addCompositionPage.vendor,
        model: state.addCompositionPage.model,
        chasis: state.addCompositionPage.chasis,
        modelErr: state.addCompositionPage.modelErr,
        vendorErr: state.addCompositionPage.vendorErr,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateVendor: (val) => { dispatch(updateVendorAC(val.target.value)); },
        updateModel: (val) => { dispatch(updateModelAC(val.target.value)); },
        updateChasis: (val) => { dispatch(updateChasisAC(val.target.value)); },
        onSend: (state) => { dispatch(sendComposition(state)) },
    }
}

const CompositionFormContainer = connect(mapStateToProps, mapDispatchToProps)(CompositionForm);
export default CompositionFormContainer;