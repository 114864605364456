import React from 'react'
import img404 from '../assets/404.jpg'

const Page404 = () => {
    return (
        <>
            <h1>Ошибка 404</h1>
            <h2>Что-то пошло не так...</h2>
            <div className={"img_container_404"}>
                <img src={img404} alt="404 Not found"/>
            </div>
            <h2>Похоже у нас нет такой страницы. Если Вы уверены, что она должна быть, пожалуйста, напишите нам на
                admin@natextolite.ru</h2>
            <h2>Мы обязательно разберемся</h2>
            {window.prerenderReady = true}
        </>
    )
}

export default Page404;