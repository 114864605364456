import React from 'react'
import {connect} from "react-redux";
import {showLoginDialogAC, logout} from "../redux/user-reducer"

import Tooltip from "@mui/material/Tooltip";
import {Link} from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MenuIcon from "@mui/icons-material/Menu";
import {Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LoginDialogContainer from "./login/Login";
import LogoutIcon from '@mui/icons-material/Logout';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: '#6dc353',
        padding: '3px',
    },
}));

const HeaderToolbar = (props) => {
    const classes = useStyles();

    const logout = () => {
        props.logout();
    }

    const renderToolbar = () => {
        if( props.isLoggedIn )
        {
            return (
                <>
                    <Toolbar>
                        {/*<Tooltip title="Сообщения" placement="bottom">
                            <Link to="/">
                                <MailIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                />
                            </Link>
                        </Tooltip>

                        <Tooltip title="Личный кабинет" placement="bottom">
                            <Link to="/">
                                <AccountBoxIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                />
                            </Link>
                        </Tooltip>

                        <Tooltip title="Меню" placement="bottom">
                            <Link to="/">
                                <MenuIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                />
                            </Link>
                        </Tooltip>
                        */}

                        <Tooltip title="Выйти из аккаунта" placement="bottom">
                            <Link to="/">
                                <LogoutIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                    onClick={logout}
                                />
                            </Link>
                        </Tooltip>
                    </Toolbar>
                </>
            )
        }
        else
        {
            return (
                <>
                    <LoginDialogContainer />
                    <Toolbar>
                        <Tooltip title="Вход/Регистрация" placement="bottom">
                            <Link to="#">
                                <AccountBoxIcon
                                    className={classes.iconButton}
                                    fontSize='medium'
                                    onClick={props.showLoginDialog}
                                />
                            </Link>
                        </Tooltip>
                    </Toolbar>
                </>
            )
        }
    }

    return (
        <>
            {renderToolbar()}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.userState.isLoggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginDialog: () => {
            dispatch(showLoginDialogAC());
        },
        logout: () => {
            dispatch(logout());
        },
    }
}

const HeaderToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderToolbar);
export default HeaderToolbarContainer;