import React, {Component} from 'react'
import {Container} from '@material-ui/core';
import logo from './logo.svg'

export default class Footer extends Component {
    render() {
        return (
            <>
                <footer>
                    <Container maxWidth="md">
                        <div className="container_footer">
                            <img
                                src={logo}
                                height="64"
                                width="64"
                                className="logo"
                                alt=""
                            />
                            <div className="brand">naTeXtolite Project</div>
                            <p className="feedback">admin@natextolite.ru</p>
                            <p className="copyright">naTeXtolite 2020-2024</p>
                            {/*<p className="copyright">© naTeXtolite 2020-2023 All Rights Reserved</p>*/}
                        </div>
                    </Container>
                </footer>
            </>
        )
    }
}
