import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {
    updateIrPn1AC, updateIrPn2AC, updateIrPn3AC,
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const IrAddForm = (props) => {

    return(
        <>
            <FormGroup3pn
                title={"ИК-приемник/ИК-ресивер"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.ir1}
                val_pn2={props.ir2}
                val_pn3={props.ir3}
                cb_pn1={props.updateIrPn1}
                cb_pn2={props.updateIrPn2}
                cb_pn3={props.updateIrPn3}
                pn1_err={props.ir1Err}
                pn2_err={props.ir2Err}
                pn3_err={props.ir3Err}
                pn1_errDesc={props.ir1ErrWhat}
                pn2_errDesc={props.ir2ErrWhat}
                pn3_errDesc={props.ir3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        ir1: state.addCompositionPage.ir_pn1,
        ir2: state.addCompositionPage.ir_pn2,
        ir3: state.addCompositionPage.ir_pn3,
        ir1Err: state.addCompositionPage.ir_pn1Err,
        ir2Err: state.addCompositionPage.ir_pn2Err,
        ir3Err: state.addCompositionPage.ir_pn3Err,
        ir1ErrWhat: state.addCompositionPage.ir_pn1ErrWhat,
        ir2ErrWhat: state.addCompositionPage.ir_pn2ErrWhat,
        ir3ErrWhat: state.addCompositionPage.ir_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateIrPn1: (val, err) => { dispatch(updateIrPn1AC(val, err)); },
        updateIrPn2: (val, err) => { dispatch(updateIrPn2AC(val, err)); },
        updateIrPn3: (val, err) => { dispatch(updateIrPn3AC(val, err)); },
    }
}
const IrAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(IrAddForm);
export default IrAddFormContainer;