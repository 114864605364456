import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {
    updateInvPn1AC, updateInvPn2AC, updateInvPn3AC
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const InvAddForm = (props) => {
    return(
        <>
            <FormGroup3pn
                title={"Инвертор"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.inv1}
                val_pn2={props.inv2}
                val_pn3={props.inv3}
                cb_pn1={props.updateInvPn1}
                cb_pn2={props.updateInvPn2}
                cb_pn3={props.updateInvPn3}
                pn1_err={props.inv1Err}
                pn2_err={props.inv2Err}
                pn3_err={props.inv3Err}
                pn1_errDesc={props.inv1ErrWhat}
                pn2_errDesc={props.inv2ErrWhat}
                pn3_errDesc={props.inv3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        inv1: state.addCompositionPage.inv_pn1,
        inv2: state.addCompositionPage.inv_pn2,
        inv3: state.addCompositionPage.inv_pn3,
        inv1Err: state.addCompositionPage.inv_pn1Err,
        inv2Err: state.addCompositionPage.inv_pn2Err,
        inv3Err: state.addCompositionPage.inv_pn3Err,
        inv1ErrWhat: state.addCompositionPage.inv_pn1ErrWhat,
        inv2ErrWhat: state.addCompositionPage.inv_pn2ErrWhat,
        inv3ErrWhat: state.addCompositionPage.inv_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateInvPn1: (val, err) => { dispatch(updateInvPn1AC(val, err)); },
        updateInvPn2: (val, err) => { dispatch(updateInvPn2AC(val, err)); },
        updateInvPn3: (val, err) => { dispatch(updateInvPn3AC(val, err)); },
    }
}
const InvAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(InvAddForm);
export default InvAddFormContainer;