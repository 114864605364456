import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {
    updateDynPn1AC, updateDynPn2AC, updateDynPn3AC,
} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const DynAddForm = (props) => {

    return(
        <>
            <FormGroup3pn
                title={"Динамики/Сабвуфер"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01976B\", а на наклейке \"BN96-26401N\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01976B\", а в поле \"Партнамбер 2\" - \"BN96-26401N\"."}
                val_pn1={props.dyn1}
                val_pn2={props.dyn2}
                val_pn3={props.dyn3}
                cb_pn1={props.updateDynPn1}
                cb_pn2={props.updateDynPn2}
                cb_pn3={props.updateDynPn3}
                pn1_err={props.dyn1Err}
                pn2_err={props.dyn2Err}
                pn3_err={props.dyn3Err}
                pn1_errDesc={props.dyn1ErrWhat}
                pn2_errDesc={props.dyn2ErrWhat}
                pn3_errDesc={props.dyn3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        dyn1: state.addCompositionPage.dyn_pn1,
        dyn2: state.addCompositionPage.dyn_pn2,
        dyn3: state.addCompositionPage.dyn_pn3,
        dyn1Err: state.addCompositionPage.dyn_pn1Err,
        dyn2Err: state.addCompositionPage.dyn_pn2Err,
        dyn3Err: state.addCompositionPage.dyn_pn3Err,
        dyn1ErrWhat: state.addCompositionPage.dyn_pn1ErrWhat,
        dyn2ErrWhat: state.addCompositionPage.dyn_pn2ErrWhat,
        dyn3ErrWhat: state.addCompositionPage.dyn_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateDynPn1: (val, err) => { dispatch(updateDynPn1AC(val, err)); },
        updateDynPn2: (val, err) => { dispatch(updateDynPn2AC(val, err)); },
        updateDynPn3: (val, err) => { dispatch(updateDynPn3AC(val, err)); },
    }
}
const DynAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(DynAddForm);
export default DynAddFormContainer;