import {fetchUsages, updateSearchPnTextActionCreator} from "../../redux/usages-reducer";
import {connect} from "react-redux";
import SearchForm from "./SearchForm";

const mapStateToProps = (state) => {
    return {
        currentSearchText: state.usagesPage.searchPnText,
        placeholder: 'BN41-02156A',
        searchType: 'Поиск по партнамберу',
        stype: 'usages',
        redirectToResults: true,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (item) => {
            dispatch(fetchUsages(item));
        },
        updateSearchText: (newText) => {
            let action = updateSearchPnTextActionCreator(newText);
            dispatch(action);
        },
    }
}

const SearchPnFormContainer = connect(mapStateToProps, mapDispatchToProps)(SearchForm);
export default SearchPnFormContainer;