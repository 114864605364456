import React from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'white',
        marginBottom: 4,
    },
    listItem: {
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 0,
    },
    listItemIcon: {
        width: 'content',
    }
});

const StaticUsagesLinksCard = (props) => {
    const classes = useStyles();
    const [dense] = React.useState(false);

    const generateStaticLinksTo = (arr, what) => {
        const items = []
        for (var i = 0, len = arr.length; i < len; i++) {
            for (var j = 0, pnsLen = arr[i].pns.length; j < pnsLen; j++) {
                items.push(
                    <>
                        <ListItem key={arr[i].pns[j]} className={classes.listItem}>
                            <TouchAppIcon color="secondary"/>
                            <Typography className={classes.content} color="textSecondary">
                                <Link
                                    href={`/services/usages/tv/${arr[i].pns[j].split("/").join("____")}`}
                                    color="inherit"
                                    undeline="always">
                                    Применение {what} {arr[i].pns[j]} в других ТВ
                                </Link>
                            </Typography>
                        </ListItem>
                    </>
                );
            }
        }
        return items;
    }

    const generateStaticLinksBlock = (composition) => {
        const items = []
        items.push(generateStaticLinksTo(composition.ssb, "майна"));
        items.push(generateStaticLinksTo(composition.psu, "блока питания"));
        items.push(generateStaticLinksTo(composition.tcn, "тикона"));
        return items;
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Использование основных блоков:
                </Typography>
                <List dense={dense}>
                    {
                        generateStaticLinksBlock(props.composition).map(value => {
                            return (
                                <ListItemText
                                    primary={value}
                                />
                            )
                        })
                    }
                </List>
            </CardContent>
        </Card>
    )
}

export default StaticUsagesLinksCard;