import fetch from 'cross-fetch';

const REQUEST_USAGES = 'REQUEST_USAGES';
const RECEIVE_USAGES = 'RECEIVE_USAGES';

const UPDATE_SEARCH_PN_TEXT = 'UPDATE_SEARCH_PN_TEXT';

const SEARCH_TYPE_PN = 'SEARCH_TYPE_PN';

let initialState = {
    searchPnText: '',
    searchType: '',
    searchedItem: '',
    isFetching: true,
    usages: {
        empty: true,
        usages: [
            {id: '', model: ''},
        ],
    }
};

const usagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SEARCH_PN_TEXT:
            return Object.assign({}, state, {
                searchPnText: action.newText
            });

        case REQUEST_USAGES:
            return Object.assign({}, state, {
                isFetching: true,
                searchedItem: state.searchPnText.split("/").join("____"),
                searchType: SEARCH_TYPE_PN,
                searchPnText: '',
            });

        case RECEIVE_USAGES:
            return Object.assign({}, state, {
                    isFetching: false,
                    usages: {
                        empty: action.usages.empty,
                        usages: action.usages.usages,
                    }
                }
            );

        default:
            return state;
    }
};

export const requestUsagesAC = () => ({type: REQUEST_USAGES})

export const receiveUsagesAC = (json) => ({
    type: RECEIVE_USAGES,
    usages: json.data
})

export function fetchUsages(item) {
    let what = item.split("/").join("____");
    return function (dispatch) {
        dispatch(requestUsagesAC());
        return fetch(process.env.REACT_APP_ORIGIN + `/api/tech/tv/usages/${what}`)
            .then(
                response => response.json()
            )
            .then(
                json => dispatch(receiveUsagesAC(json))
            )
    }
}

export const updateSearchPnTextActionCreator = (text) =>
    ({type: UPDATE_SEARCH_PN_TEXT, newText: text})

export default usagesReducer;
