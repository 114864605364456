import {FormGroup3pn} from "../../FormGroup";
import React from "react";
import {updateSsbPn1AC, updateSsbPn2AC, updateSsbPn3AC} from "../../../redux/add-composition-reducer";
import {connect} from "react-redux";

const SsbAddForm = (props) => {

    return(
        <>
            <FormGroup3pn
                title={"SSB/Майн/Матплата/Main"}
                description={"Внимательно осмотрите плату и наклейки на ней. После этого укажите здесь партнамберы, которые видите.\n" +
                "                            Каждый партнамбер указывайте в отдельном поле.\n" +
                "                            Например, на плате вы видите \"BN41-01958A\", а на наклейке \"BN94-06555H\".\n" +
                "                            В поле \"Партнамбер 1\" укажите \"BN41-01958A\", а в поле \"Партнамбер 2\" - \"BN94-06555H\"."}
                val_pn1={props.ssb1}
                val_pn2={props.ssb2}
                val_pn3={props.ssb3}
                cb_pn1={props.updateSsbPn1}
                cb_pn2={props.updateSsbPn2}
                cb_pn3={props.updateSsbPn3}
                pn1_err={props.ssb1Err}
                pn2_err={props.ssb2Err}
                pn3_err={props.ssb3Err}
                pn1_errDesc={props.ssb1ErrWhat}
                pn2_errDesc={props.ssb2ErrWhat}
                pn3_errDesc={props.ssb3ErrWhat}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        ssb1: state.addCompositionPage.ssb_pn1,
        ssb2: state.addCompositionPage.ssb_pn2,
        ssb3: state.addCompositionPage.ssb_pn3,
        ssb1Err: state.addCompositionPage.ssb_pn1Err,
        ssb2Err: state.addCompositionPage.ssb_pn2Err,
        ssb3Err: state.addCompositionPage.ssb_pn3Err,
        ssb1ErrWhat: state.addCompositionPage.ssb_pn1ErrWhat,
        ssb2ErrWhat: state.addCompositionPage.ssb_pn2ErrWhat,
        ssb3ErrWhat: state.addCompositionPage.ssb_pn3ErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSsbPn1: (val, err) => {
            dispatch(updateSsbPn1AC(val, err));
        },
        updateSsbPn2: (val, err) => {
            dispatch(updateSsbPn2AC(val, err));
        },
        updateSsbPn3: (val, err) => {
            dispatch(updateSsbPn3AC(val, err));
        },
    }
}
const SsbAddFormContainer = connect(mapStateToProps, mapDispatchToProps)(SsbAddForm);
export default SsbAddFormContainer;