import React from "react";
import StyledTextField from "../entities/StyledTextField";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import StyledButton from "../entities/StyledButton";
import {connect} from "react-redux";
import {
    updateLoginLoginText, updateLoginPasswordText,
    loginStartAC, loginFailedAC, loginFailedInvalidCredentialsAC,
    loginSuccessAC, forgotPassAC, resetPassAC, updateRegPwdText, updateRegPwdConfirmText
} from "../../redux/user-reducer";
import Spinner from "../Spinner";
import Typography from "@material-ui/core/Typography";
import ForgotPassViewContainer from "./ForgotPass";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(10),
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#202938',
        alignContent: 'center',
    },
}));

async function loginUser(credentials) {
    return fetch(process.env.REACT_APP_ORIGIN + '/api/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(credentials)
    })
        .then( data => data.json() )
}

function LoginDialogView(props) {
    const classes = useStyles();

    const handleLogin = async (e) => {
        e.preventDefault();

        if( !props.login || !props.password )
        {
            return;
        }

        if( props.loginErr || props.passwordErr )
        {
            return;
        }

        props.loginStart();

        const response = await loginUser({
            login: props.login,
            pass: props.password,
        });

        if( response.statusCode === 200 )
        {
            props.loginSuccess();
        }
        else if( response.statusCode === 401 )
        {
            props.loginFailedInvalidCreds();
        }
        else
        {
            props.loginFailed( (typeof response.data.reason !== "undefined") ? response.data.reason : '' );
        }
    }

    const handleForgot = () => {
        props.forgot();
    };

    // Render options
    if( props.isFetching )
    {
        return (<Spinner />);
    }

    const renderCredentialsNotice = () => {
        if( props.isInvalidCreds )
        {
            return (
                <>
                    <Grid item lg={8} md={10} sm={12}>
                        <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            Ошибка авторизации (неверный пароль или логин).
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            Проверьте данные и попробуйте снова
                        </Typography>
                    </Grid>
                </>
            )
        }
    }

    const renderKnownErrorNotice = () => {
        if( props.loginErrReason )
        {
            return (
                <>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item lg={8} md={10} sm={12}>
                            <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                                {props.loginErrReason}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    if( props.isLoginFailed && !props.loginErrReason )
    {
        return (
            <>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={8} md={10} sm={12}>
                       <Typography className={classes.title} color="textSecondary" align={'center'} gutterBottom>
                            Неизвестная ошибка, попробуйте снова через несколько минут
                       </Typography>
                    </Grid>
                </Grid>
            </>
        )
    }

    if( props.isForgotPass )
    {
        return (<ForgotPassViewContainer />)
    }

    return (
        <>
            <form onSubmit={handleLogin}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item lg={8} md={10} sm={12}>
                        <StyledTextField
                            label="Имя пользователя или почта"
                            type="text"
                            value={props.login}
                            onChange={props.loginChanged}
                            err={props.loginErr}
                            errWhat={props.loginErrWhat}
                            placeholder="Login"
                            icon={<AssignmentIndIcon />}
                            className={classes.root}
                        />

                        <StyledTextField
                            label="Пароль"
                            type="password"
                            value={props.password}
                            onChange={props.passwordChanged}
                            err={props.passwordErr}
                            errWhat={props.passwordErrWhat}
                            placeholder="Password"
                            icon={<VpnKeyIcon />}
                            className={classes.root}
                        />
                    </Grid>
                    <Grid item lg={8} md={10} sm={12}>
                        <StyledButton
                            label={"Войти"}
                            type={"submit"} />

                        <StyledButton
                            label={"Забыл пароль"}
                            type={"button"}
                            onClick={handleForgot}
                        />
                    </Grid>
                    { renderCredentialsNotice() }
                    { renderKnownErrorNotice() }
                </Grid>
            </form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        login: state.userState.loginLogin,
        loginErr: state.userState.loginLoginErr,
        loginErrWhat: state.userState.loginLoginErrWhat,
        loginErrReason: state.userState.loginErrReason,

        password: state.userState.loginPasswd,
        passwordErr: state.userState.loginPasswdErr,
        passwordErrWhat: state.userState.loginPasswdErrWhat,

        isFetching: state.userState.isFetching,
        isInvalidCreds: state.userState.invalidCredentials,
        isLoginFailed: state.userState.loginFailed,

        isForgotPass: state.userState.forgotPassword,
        waitCode: state.userState.forgotWaitCode,

        newPassword: state.userState.regPasswd,
        newPasswordErr: state.userState.regPasswdErr,
        newPasswordErrWhat: state.userState.regPasswdErrWhat,

        newPasswordConfirm: state.userState.regPasswdConfirm,
        newPasswordConfirmErr: state.userState.regPasswdConfirmErr,
        newPasswordConfirmErrWhat: state.userState.regPasswdConfirmErrWhat,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginChanged: (e) => {
            dispatch(updateLoginLoginText(e.target.value));
        },
        passwordChanged: (e) => {
            dispatch(updateLoginPasswordText(e.target.value));
        },
        loginStart: () => {
            dispatch(loginStartAC());
        },
        loginFailedInvalidCreds: () => {
            dispatch(loginFailedInvalidCredentialsAC());
        },
        loginFailed: (reason) => {
            dispatch(loginFailedAC(reason));
        },
        loginSuccess: () => {
            dispatch(loginSuccessAC());
        },
        forgot: () => {
            dispatch(forgotPassAC());
        },
        resetPass: () => {
            dispatch(resetPassAC());
        },
        newPasswordChanged: (e) => {
            dispatch(updateRegPwdText(e.target.value));
        },
        newPasswordConfirmChanged: (e) => {
            dispatch(updateRegPwdConfirmText(e.target.value));
        },
    }
}

const LoginDialogViewContainer = connect(mapStateToProps, mapDispatchToProps)(LoginDialogView);
export default LoginDialogViewContainer;
