import fetch from "cross-fetch";
import {receiveCompositionAC, requestCompositionAC} from "./composition-reducer";

const SHOW_LOGIN_DIALOG = 'SHOW_LOGIN_DIALOG';
const HIDE_LOGIN_DIALOG = 'HIDE_LOGIN_DIALOG';

const LOGIN_LOGIN_TEXT_CHANGED = 'LOGIN_LOGIN_TEXT_CHANGED';
const LOGIN_PASSWORD_TEXT_CHANGED = 'LOGIN_PASSWORD_TEXT_CHANGED';
const LOGIN_START = 'LOGIN_START';
const LOGIN_FAILED_BY_UNKNOWN = 'LOGIN_FAILED_BY_UNKNOWN';
const LOGIN_FAILED_BY_CREDENTIALS = 'LOGIN_FAILED_BY_CREDENTIALS';
const LOGIN_OK = 'LOGIN_OK';

const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const PASS_RESET_REQUEST = 'PASS_RESET_REQUEST';
const RESET_PASSWD_TEXT_CHANGED = 'RESET_PASSWD_TEXT_CHANGED';
const RESET_PASSWD_CONFIRM_TEXT_CHANGED = 'RESET_PASSWD_CONFIRM_TEXT_CHANGED';
const RESET_CODE_TEXT_CHANGED = 'RESET_CODE_TEXT_CHANGED';
const RESET_RAISE_UNKNOWN_ERROR = 'RESET_RAISE_UNKNOWN_ERROR';
const RESET_INVALID_CODE_ERROR = 'RESET_INVALID_CODE_ERROR';
const SET_FETCHING = 'SET_FETCHING';
const PASS_CHANGED = 'PASS_CHANGED';


const REGISTER_LOGIN_TEXT_CHANGED = 'REGISTER_LOGIN_TEXT_CHANGED';
const REGISTER_MAIL_TEXT_CHANGED = 'REGISTER_MAIL_TEXT_CHANGED';
const REGISTER_PASSWD_TEXT_CHANGED = 'REGISTER_PASSWD_TEXT_CHANGED';
const REGISTER_PASSWD_CONFIRM_TEXT_CHANGED = 'REGISTER_PASSWD_CONFIRM_TEXT_CHANGED';
const REGISTER_CODE_TEXT_CHANGED = 'REGISTER_CODE_TEXT_CHANGED';
const REG_START = 'REG_START';
const REG_WAIT_CODE = 'REG_WAIT_CODE';
const REG_WAIT_CODE_CONFIRM = 'REG_WAIT_CODE_CONFIRM';
const REG_INVALID_CODE = 'REG_INVALID_CODE';
const REG_UNKNOWN_ERROR = 'REG_UNKNOWN_ERROR';
const REG_KNOWN_ERROR = 'REG_KNOWN_ERROR';
const REG_CLEAR_ERROR = 'REG_CLEAR_ERROR';
const REG_DONE = 'REG_DONE';
const REG_AUTO_LOGIN = 'REG_AUTO_LOGIN';
const REG_AUTO_LOGIN_FAILED = 'REG_AUTO_LOGIN_FAILED';

const LOGOUT = 'LOGOUT';
const RESET = 'RESET';


let initialState = {
    isLoggedIn: false,
    showLoginDialog: false,
    isFetching: false,

    /* Login/Register data */
    // login tab data:
    loginLogin: '',
    loginLoginErr: false,
    loginLoginErrWhat: '',
    loginPasswd: '',
    loginPasswdErr: false,
    loginPasswdErrWhat: '',
    loginFailed: false,
    invalidCredentials: false,
    loginErrReason: '',

    // forgot password functionality
    forgotPassword: false,
    forgotWaitCode: false,

    newPasswd: '',
    newPasswdErr: false,
    newPasswdErrWhat: '',

    newPasswdConfirm: '',
    newPasswdConfirmErr: false,
    newPasswdConfirmErrWhat: '',

    passResetCode: '',
    passResetUnknownErr: false,
    passResetInvalidCodeErr: false,
    passResetErrReason: '',

    passChanged: false,


    // register tab data:
    regLogin: '',
    regLoginErr: false,
    regLoginErrWhat: '',

    regMail: '',
    regMailErr: false,
    regMailErrWhat: '',

    regPasswd: '',
    regPasswdErr: false,
    regPasswdErrWhat: '',

    regPasswdConfirm: '',
    regPasswdConfirmErr: false,
    regPasswdConfirmErrWhat: '',

    regIsFetching: false,
    regIsWaitingVerify: false,
    regVerificationCode: '',
    regInvalidCode: false,
    regUnknownError: false,
    regKnownError: false,
    regKnownErrReason: '',
};

let checkCyrillic = ( text ) => {
    let ru = /[а-яё]+/i.test(text);
    return ru;
}

const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

let checkMailFormatValid = ( text ) => {
    return EMAIL_REGEXP.test(text);
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOGIN_DIALOG:
            return Object.assign({}, state, {
                showLoginDialog: true,
            });
        case HIDE_LOGIN_DIALOG:
            return Object.assign({}, state, {
                showLoginDialog: false,
            });

        case LOGIN_LOGIN_TEXT_CHANGED:
            return Object.assign({}, state, {
                loginLogin: action.newText,
                loginLoginErr: action.isErr,
                loginLoginErrWhat: action.errWhat,
                invalidCredentials: false,
                loginFailed: false,
            });

        case LOGIN_PASSWORD_TEXT_CHANGED:
            return Object.assign({}, state, {
                loginPasswd: action.newText,
                loginPasswdErr: action.isErr,
                loginPasswdErrWhat: action.errWhat,
                invalidCredentials: false,
                loginFailed: false,
            });


        case LOGIN_START:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case LOGIN_FAILED_BY_CREDENTIALS:
            return Object.assign({}, state, {
                invalidCredentials: true,
                isFetching: false,
                loginErrReason: '',
            });
        case LOGIN_FAILED_BY_UNKNOWN:
            return Object.assign({}, state, {
                loginFailed: true,
                isFetching: false,
                loginErrReason: action.reason,
            });
        case LOGIN_OK:
            return Object.assign({}, state, {
                isFetching: false,
                showLoginDialog: false,
                loginLogin: '',
                loginPasswd: '',
                isLoggedIn: true,
            });

        case FORGOT_PASSWORD:
            return Object.assign({}, state, {
                forgotPassword: true,
            });

        case PASS_RESET_REQUEST:
            return Object.assign({}, state, {
                forgotWaitCode: true,
            });

        case RESET_PASSWD_TEXT_CHANGED: {
            return Object.assign({}, state, {
                newPasswd: action.newText,
                newPasswdErr: action.isErr,
                newPasswdErrWhat: action.errWhat,
            });
        }

        case RESET_PASSWD_CONFIRM_TEXT_CHANGED: {
            if( !action.isErr )
            {
                if( action.newText !== state.newPasswd )
                {
                    action.isErr = true;
                    action.errWhat = 'Введенные пароли не совпадают';
                }
            }
            return Object.assign({}, state, {
                newPasswdConfirm: action.newText,
                newPasswdConfirmErr: action.isErr,
                newPasswdConfirmErrWhat: action.errWhat,
            });
        }

        case RESET_CODE_TEXT_CHANGED:
            return Object.assign({}, state, {
                passResetCode: action.newText,
            });

        case RESET_RAISE_UNKNOWN_ERROR:
            return Object.assign({}, state, {
                passResetUnknownErr: true,
                passResetErrReason: action.reason,
            });

        case RESET_INVALID_CODE_ERROR:
            return Object.assign({}, state, {
                passResetInvalidCodeErr: true,
            });

        case SET_FETCHING:
            return Object.assign({}, state, {
                isFetching: action.isFetching,
            });

        case PASS_CHANGED:
            return Object.assign({}, state, {
                forgotWaitCode: false,
                passChanged: true,
            });








        case REGISTER_LOGIN_TEXT_CHANGED: {

            return Object.assign({}, state, {
                regLogin: action.newText,
                regLoginErr: action.isErr,
                regLoginErrWhat: action.errWhat,
            });
        }

        case REGISTER_MAIL_TEXT_CHANGED: {
            return Object.assign({}, state, {
                regMail: action.newText,
                regMailErr: action.isErr,
                regMailErrWhat: action.errWhat,
            });
        }

        case REGISTER_PASSWD_TEXT_CHANGED: {
            let passMatch = true;
            if( action.newText !== state.regPasswdConfirm )
            {
                action.isErr = true;
                action.errWhat = 'Введенные пароли не совпадают';
                passMatch = false;
            }
            return Object.assign({}, state, {
                regPasswd: action.newText,
                regPasswdErr: action.isErr,
                regPasswdErrWhat: action.errWhat,
                regPasswdConfirmErr: passMatch ? false : state.regPasswdConfirmErr,
                regPasswdConfirmErrWhat : passMatch ? "" : state.regPasswdConfirmErrWhat
            });
        }

        case REGISTER_PASSWD_CONFIRM_TEXT_CHANGED: {
            let passMatch = true;
            if( !action.isErr )
            {
                if( action.newText !== state.regPasswd )
                {
                    action.isErr = true;
                    action.errWhat = 'Введенные пароли не совпадают';
                    passMatch = false;
                }
            }
            return Object.assign({}, state, {
                regPasswdConfirm: action.newText,
                regPasswdConfirmErr: action.isErr,
                regPasswdConfirmErrWhat: action.errWhat,
                regPasswdErr: passMatch ? false : state.regPasswdErr,
                regPasswdErrWhat : passMatch ? "" : state.regPasswdErrWhat
            });
        }

        case REGISTER_CODE_TEXT_CHANGED:
            return Object.assign({}, state, {
                regVerificationCode: action.newText,
            });


        case REG_START:
            return Object.assign({}, state, {
                regIsFetching: true,
            });
        case REG_WAIT_CODE:
            return Object.assign({}, state, {
                regIsFetching: false,
                regIsWaitingVerify: true,
            });
        case REG_WAIT_CODE_CONFIRM:
            return Object.assign({}, state, {
                regIsFetching: true,
            });
        case REG_INVALID_CODE:
            return Object.assign({}, state, {
                regIsFetching: false,
                regInvalidCode: true,
            });
        case REG_UNKNOWN_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: true,
            });
        case REG_KNOWN_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: false,
                regKnownError: true,
                regKnownErrReason: action.reason,
            });
        case REG_CLEAR_ERROR:
            return Object.assign({}, state, {
                regIsFetching: false,
                regUnknownError: false,
                regKnownError: false,
                regKnownErrReason: '',
            });
        case REG_DONE:
            return Object.assign({}, state, {
                regIsWaitingVerify: false,
                regUnknownError: false,
                regInvalidCode: false,
            });
        case REG_AUTO_LOGIN:
            return Object.assign({}, state, {
                regIsFetching: false,
                regIsWaitingVerify: false,
                regUnknownError: false,
                regInvalidCode: false,
                regLogin: '',
                regMail: '',
                regPasswd: '',
                regPasswdConfirm: '',
                regVerificationCode: '',
                showLoginDialog: false,
                isLoggedIn: true,
            });

        case REG_AUTO_LOGIN_FAILED:
            return Object.assign({}, state, {
                showLoginDialog: false,
            });
        case LOGOUT:
            localStorage.removeItem('token');
            return Object.assign({}, state, {
                isLoggedIn: false,
            });

        case RESET:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
};

export const showLoginDialogAC = () => ({type: SHOW_LOGIN_DIALOG})
export const hideLoginDialogAC = () => ({type: HIDE_LOGIN_DIALOG})

export const loginLoginTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOGIN_LOGIN_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat,
})
export const loginPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: LOGIN_PASSWORD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat,
})

export const loginStartAC = () => ({type: LOGIN_START})
export const loginFailedInvalidCredentialsAC = () => ({type: LOGIN_FAILED_BY_CREDENTIALS})
export const loginFailedAC = (reason) => ({
    type: LOGIN_FAILED_BY_UNKNOWN,
    reason: reason,
})
export const loginSuccessAC = () => ({type: LOGIN_OK})
export const forgotPassAC = () => ({type: FORGOT_PASSWORD})
export const resetPassAC = () => ({type: PASS_RESET_REQUEST})
export const resetUnknownErrAC = (reason) => ({
    type: RESET_RAISE_UNKNOWN_ERROR,
    reason: reason,
})
export const resetInvalidCodeAC = () => ({type: RESET_INVALID_CODE_ERROR})
export const passChangedAC = () => ({type: PASS_CHANGED})

export const newPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: RESET_PASSWD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const newPasswordConfirmTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: RESET_PASSWD_CONFIRM_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const newPassCodeTextUpdatedAC = (newText) => ({type: RESET_CODE_TEXT_CHANGED, newText: newText})

export const setFetchingAC = (isFetching) => ({type: SET_FETCHING, isFetching: isFetching})



export function updateNewPwdText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 6 || newText.length > 32;
        if( isErr )
        {
            what = isRu ? "Используйте буквы английского алфавита" : "Пароль должен быть от 6 до 32 символов";
        }
        dispatch(newPasswordTextUpdatedAC( newText, isErr, what ) )
    }
}

export function updateNewPwdConfirmText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(newPasswordConfirmTextUpdatedAC( newText, isRu, isRu ? "Используйте буквы английского алфавита" : "" ) )
    }
}
















export const regLoginTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: REGISTER_LOGIN_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const regMailTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: REGISTER_MAIL_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const regPasswordTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: REGISTER_PASSWD_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})

export const regPasswordConfirmTextUpdatedAC = (newText, isErr, errWhat) => ({
    type: REGISTER_PASSWD_CONFIRM_TEXT_CHANGED,
    newText: newText,
    isErr: isErr,
    errWhat: errWhat
})
export const regCodeTextUpdatedAC = (newText) => ({type: REGISTER_CODE_TEXT_CHANGED, newText: newText})
export const regStartAC = () => ({type: REG_START})
export const regWaitVerificationCodeAC = () => ({type: REG_WAIT_CODE})
export const regWaitCodeConfirmAC = () => ({type: REG_WAIT_CODE_CONFIRM})
export const regInvalidCodeAC = () => ({type: REG_INVALID_CODE})
export const regUnknownErrorAC = () => ({type: REG_UNKNOWN_ERROR})
export const regKnownErrorAC = (reason) => ({
    type: REG_KNOWN_ERROR,
    reason: reason,
})
export const regClearErrorsAC = () => ({type: REG_CLEAR_ERROR})
export const regSuccessAC = () => ({type: REG_DONE})
export const regAutoLoginAC = () => ({type: REG_AUTO_LOGIN})
export const regAutoLoginFailedAC = () => ({type: REG_AUTO_LOGIN_FAILED})

export const logoutAC = () => ({type: LOGOUT})



export function updateLoginLoginText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(loginLoginTextUpdatedAC( newText, isRu, isRu ? "Используйте буквы английского алфавита" : "" ) )
    }
}

export function updateLoginPasswordText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(loginPasswordTextUpdatedAC( newText, isRu, isRu ? "Используйте буквы английского алфавита" : "" ) )
    }
}

export function updateRegLoginText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 4 || newText.length > 32;
        if( isErr )
        {
            what = isRu ? "Используйте буквы английского алфавита" : "Логин должен быть от 4 до 32 символов";
        }
        dispatch(regLoginTextUpdatedAC( newText, isErr, what ) )
    }
}

export function updateRegMailText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || !checkMailFormatValid(newText);
        if( isErr )
        {
            what = isRu ? "Используйте буквы английского алфавита" : "Некорректный формат email";
        }
        dispatch(regMailTextUpdatedAC( newText, isErr, what ) )
    }
}

export function updateRegPwdText(newText) {
    return function (dispatch) {
        let what = '';
        let isRu = checkCyrillic(newText);

        let isErr = isRu || newText.length < 6 || newText.length > 32;
        if( isErr )
        {
            what = isRu ? "Используйте буквы английского алфавита" : "Пароль должен быть от 6 до 32 символов";
        }
        dispatch(regPasswordTextUpdatedAC( newText, isErr, what ) )
    }
}

export function updateRegPwdConfirmText(newText) {
    return function (dispatch) {
        let isRu = checkCyrillic(newText);
        dispatch(regPasswordConfirmTextUpdatedAC( newText, isRu, isRu ? "Используйте буквы английского алфавита" : "" ) )
    }
}

export function initialSync() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/whoami', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        } )
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if( json.statusCode === 200 )
                    {
                        dispatch(loginSuccessAC(json))
                    }
                    else
                    {
                        dispatch(logoutAC(json))
                    }
                }
            )
    }
}

export function logout() {
    return function (dispatch) {
        return fetch(process.env.REACT_APP_ORIGIN + '/api/users/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        } )
            .then(
                response => response.json()
            )
            .then(
                (json) => {
                    if( json.statusCode === 200 )
                    {
                        dispatch(logoutAC(json))
                    }
                }
            )
    }
}

export const resetStateAC = () => ({type: RESET})


export default userReducer;