import React from 'react'
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {SecondaryUsagesResultContainer} from "./Usages";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        margin: 10,
        background: '#202938',
        paddingBottom: 0,
        paddingTop: 0,
    },
    title: {
        fontSize: 17,
        fontWeight: 500,
        color: '#6dc353',
    },
    content: {
        fontSize: 15,
        fontWeight: 500,
        color: 'white',
        marginBottom: 4,
    },
});

const DynamicUsagesLinksCard = (props) => {
    const classes = useStyles();

    const renderResults = () => {
        if (props.usages.empty) {
            return (
                <>
                    <Typography className={classes.content} color="textSecondary">
                        Кликните на строчку с партнамбером в дереве <br/>состава слева и мы проверим в каких еще
                        тв <br/> используется выбранный модуль/плата
                    </Typography>
                </>
            )
        } else {
            return (
                <Typography className={classes.content} color="textSecondary">
                    <SecondaryUsagesResultContainer/>
                </Typography>
            )
        }
    }

    return (
        <>
            <div id="DynamicUsagesLinksBlock">
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Карта использования
                        </Typography>
                        {renderResults()}
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        usages: state.compositionPage.secondaryUsages,
    }
}

const DynamicUsagesLinksCardContainer = connect(mapStateToProps, {})(DynamicUsagesLinksCard);
export default DynamicUsagesLinksCardContainer;