import React, {Component} from 'react'
import SearchModelFormContainer from "../components/search/SearchModelForm";
import SearchPnFormContainer from "../components/search/SearchPnForm";
import logo from "../components/logo.svg";
import MetaTags from "react-meta-tags";
import StyledButton from "../components/entities/StyledButton";
import {Link} from "react-router-dom";
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import {Grid} from "@material-ui/core";

export default class Home extends Component {
    render() {
        return (
            <>
                <MetaTags>
                    <title>{`наТекстолите Главная`}</title>
                    <meta name="description" content={`наТекстолите Главная`} />
                    <meta property="og:title" content="naTeXtolite" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`${window.location.href}`} />
                    <meta property="og:image" content={logo} />
                    <meta property="og:description" content={`наТекстолите Главная`} />
                </MetaTags>
                <h1> Добро пожаловать!</h1>
                <p>На данный момент НаТекстолите вы можете найти технические составы телевизоров наиболее
                    распространенных производителей, таких как Samsung, LG, Sony и др. Мы ведем
                    работу по наполнению базы, а теперь и любой желающий может присоединиться и внести
                    состав ТВ, которого еще нет в базе (для этого нажмите кнопку "Добавить состав" внизу страницы)
                </p>
                <p>
                    Чтобы получить состав ТВ, введите его модель в поле ниже и нажмите "Искать"
                </p>
                <SearchModelFormContainer/>
                <p>
                    Часто, бывает полезно знать в каких моделях ТВ установлен тот или иной модуль. Однако информацию
                    приходится собирать по крупицам, затрачивая уйму времени.
                    Специально для таких случаев мы добавили "реверсивный поиск" - введите парт-намбер искомой
                    детали и мы покажем в каких моделях ТВ она установлена.
                </p>
                <p>
                    Введите парт-намбер искомой детали в поле ниже и нажмите кнопку "Искать"
                </p>
                <SearchPnFormContainer/>

                <p>
                    Доступно внесение составов в базу!
                    Для зарегистрированных пользователей стало доступно добавление составов неизвестных ТВ.
                    Теперь каждый может принять участие в улучшении и развитии сервиса.
                </p>

                <Grid container direction="row" justify="center" alignItems="center" >
                    <Link to={"/services/composition/tv/add"}  style={{ textDecoration: 'none' }}>
                        <StyledButton
                            label={"Добавить состав"}
                            icon={<QueuePlayNextIcon/>}
                        />
                    </Link>
                </Grid>

                {window.prerenderReady = true}
            </>
        )
    }
}